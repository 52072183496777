import React, { useEffect, useState } from 'react'
import "./Services.css"

import vid from '../../ASSETS/Brand Promotion.mp4'


function SPOne() {

    return (
        <div className='SPOne'>
            <div className="SPContainer">
                
                <video playsInline src={vid} autoPlay loop muted controlsList='nodownload' controls></video>

            </div>
            


            
        </div>
    )
}

export default SPOne