import React from 'react'
import Card1 from './Card1'
import Card2 from './Card2'
import Card3 from './Card3'
import './Segments.css'
import { Link } from "react-router-dom"
import image1 from '../ASSETS/website starfluenza.png'
import mobileImage from '../ASSETS/mobilewebsite.png'
import CircleCards from '../CIRCLECARDS/CircleCards'
function Segments() {
  return (
    <div className='segments'>
      <h1 id='segmentTitle'>WE GET YOU</h1>

      <Link id='segment_desktop' to={'/Services'}>
        <CircleCards/>
      {/* <img id='segment_desktop' src={image1} alt="" /> */}
      </Link>

      <Link to={'/Services'}>
      <CircleCards/>
        
        {/* <img id='segment_mobile' src={mobileImage} alt="" /> */}
      </Link>
      {/* <h1 id='segmentTitle'>WE GET YOU</h1>
      <div className="segment_cards"> */}
        {/* <img src={image1} alt="" /> */}
          {/* <Link to={'/Services'}> <Card2 /></Link>
          <Link to={'/Services'}>
          <Card1 /> </Link>
          <Link to={'/Services'}> <Card3 /></Link> */}
        {/* </Link> */}


      {/* </div> */}
    </div>
  )
}

export default Segments



























// import React from 'react'
// import CircleCards from '../CIRCLECARDS/CircleCards'
// import './Segments.css'
// import { Link } from "react-router-dom"

// import image1 from '../ASSETS/website starfluenza.png'
// import mobileImage from '../ASSETS/mobilewebsite.png'

// function Segments() {
//   return (
//     <div className='segments'>


//       {/* <h1>We Get</h1> */}

//       <Link id='segment_desktop' to={'/Services'}>
//         <CircleCards style={{ top: '6%', left: '50%', transform: 'translate(-50%, -50%)' }}/>
//       </Link>

//       <Link to={'/Services'}>
//         <CircleCards style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}/>
//       </Link>

//     </div>
//   )
// }

// export default Segments;
