import React from 'react'
import vid1 from '../ASSETS/mainVideo.mp4'
import vid2 from '../ASSETS/CATALOGUE/316885373_845066486738316_5973672268781634146_n.MP4'

import vidBack from '../ASSETS/CATALOGUE/30 sec Paravti Nair 1920 x 1080 .mp4'
import vid3 from '../ASSETS/CATALOGUE/bd4a4e2c-ceba-4fcd-be78-5cdecb845e49.MP4'
import vid4 from '../ASSETS/CATALOGUE/Muscle Curve-NNM(W).mp4'
import vid5 from '../ASSETS/CATALOGUE/Paras Kalnawat_main video.MP4'
import vid6 from '../ASSETS/CATALOGUE/shefalli bagga_1.mp4'
import vid7 from '../ASSETS/CATALOGUE/ShivThakare_Socialmedia_9x16.mp4'


function CatalogueTwo() {
    return (
        <div className='catalogue_two'>
            <video playsInline controlsList='nodownload' controls src={vid1} autoPlay loop muted></video>
            <h2>VIDEOS</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ex.</p>
            <div className="catalogue_videos">
                <div className="vid_card_top">
                    <div className="vid_card_top_left">
                        <video controlsList='nodownload' controls src={vid2} autoPlay loop muted></video>

                    </div>
                    <div className="vid_card_top_right">
                        <div className="vid_card_top_right_top">
                            <video controlsList='nodownload' controls src={vidBack} autoPlay loop muted></video>

                        </div>
                        <div className="vid_card_top_right_btm">
                            <video src={vid4} autoPlay loop muted></video>
                            <video src={vid4} autoPlay loop muted></video>
                            <video src={vid4} autoPlay loop muted></video>


                        </div>

                    </div>

                </div>

                <div className="vid_card_btm">
                    <div className="vid_card_btm_card">
                        <video controlsList='nodownload' controls src={vid3} autoPlay loop muted></video>

                    </div>
                    <div className="vid_card_btm_card">
                        <video controlsList='nodownload' controls src={vid6} autoPlay loop muted></video>

                    </div>
                    <div className="vid_card_btm_card">
                        <video controlsList='nodownload' controls src={vid5} autoPlay loop muted></video>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default CatalogueTwo