import React from 'react'
import Footer from '../Footer'
import FreeConsultation from './FreeConsultation'



function Index() {
  return (
    <div>
<FreeConsultation/>
<Footer/>
    </div>
  )
}

export default Index