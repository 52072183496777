import React, { useEffect, useState } from 'react'
import image1 from '../../ASSETS/founder/Ourvision.png'
import image2 from '../../ASSETS/founder/OurMission.png'
import image3 from '../../ASSETS/founder/Ourprocess.png'


function AboutSeg1() {

    const [segment, setsegment] = useState([])

    const data = [
        {
            title:"Our Vision",
            image: image1,
            p1: "Starfluenza aims at helping Brands become the real Stars of their Niche by customizing a perfect celebrity marketing plan."
          },
        {
            title:"Our Mission",
            image: image2,
            p1: "The digital age has made it easier for people to reach numbers, we aim to help you reach the hearts of your audience."
           },
        {
            title:"Our Process",
            image: image3,
            p1: "We have seen businesses grow based on names, concepts and art but now is the time when we will combine them together and give it a celebrity face that shows the world, “What your Brand really is?” "
           }
    ]

    useEffect(() => {
      
        setsegment(data);

      return () => {

      }
    }, [])
    

  return (
    <div className='AboutSeg1'>
       <h1>About Us</h1>
       <h4 style={{"fontFamily" : "Mansalva"}} >ADDING <span> STARS </span> TO YOUR BRAND</h4>
       <div className="allAboutSeg">
       {segment.map((e,i) => {
        return <div key={i} className={ i%2 === 0 ? "eachSegment" : "eachSegment segEven"}>
           <img src={e.image} className="segImage" alt="" />
            <div className="aboutSegText">
            <h2>{e.title}</h2>
            <p>{e.p1}</p>
            
            </div>
        </div>
       })}
       </div>
    </div>
  )
}

export default AboutSeg1