import React from 'react'
import AboutVid from '../../ASSETS/About us Star final.mp4'
import './AboutUs.css'
import AboutMobile from '../../ASSETS/mobile about us - Starfluenza (3).mp4'


function AboutUsSeg2() {
  return (
    <div className='aboutSeg2'>

        <h1>About Us</h1>

        <video playsInline id='About_desktop' style={{"height" : "86vh"}} src={AboutVid} autoPlay loop muted></video>
        <video playsInline id='About_Mobile' src={AboutMobile} autoPlay loop muted></video>
    </div>
  )
}

export default AboutUsSeg2