import React from 'react'
import vid1 from '../ASSETS/CATALOGUE/30 sec Paravti Nair 1920 x 1080 .mp4'
import vid2 from '../ASSETS/CATALOGUE/316885373_845066486738316_5973672268781634146_n.MP4'
import vidBack from '../ASSETS/mainVideo.mp4'
import vid3 from '../ASSETS/CATALOGUE/bd4a4e2c-ceba-4fcd-be78-5cdecb845e49.MP4'
import vid4 from '../ASSETS/CATALOGUE/Muscle Curve-NNM(W).mp4'
import vid5 from '../ASSETS/CATALOGUE/Paras Kalnawat_main video.MP4'

// views\src\ASSETS\mainVideo.mp4
// C:\Users\LENOVO\Desktop\MediaMatte\views\src\ASSETS\Brand Promotion.mp4
// C:\Users\LENOVO\Desktop\MediaMatte\views\src\ASSETS\AboutUsMobile.mp4
function CatalogueOne() {
  return (
    <div className='catalogue_one'>
        <video src={vidBack} autoPlay loop muted></video>
        <h1>OUR STAR <br /> CAMPAIGNS</h1>
        <div id='tilt_card_one' className="catalogue_card">
            <video controls src={vid4} autoPlay loop muted></video>
        </div>
        <div id='tilt_card_two' className="catalogue_card">
            <video src={vid1} autoPlay loop muted></video>
        </div> 
        <div id='tilt_card_three' className="catalogue_card">
            <video src={vid3} autoPlay loop muted></video>
        </div> 
        <div id='tilt_card_four' className="catalogue_card">
            <video src={vid2} autoPlay loop muted></video>
        </div>
        
        <div id='tilt_card_five' className="catalogue_card">
            <video src={vid5} autoPlay loop muted></video>
        </div>

<div className="arrow_down_animation">
<div class="arrow arrow-first"></div>
        <div class="arrow arrow-second"></div>
</div>
        
    </div>
  )
}

export default CatalogueOne