import React from 'react'
import MainBox from './MainBox'
import './PageOne.css'

function PageOne() {
  return (
    <div className='pageOne'>
        <MainBox/>
        
    </div>
  )
}

export default PageOne