import React from 'react'
import { Link } from 'react-router-dom'
import "./Strip.css"
import logo from '../ASSETS/logo.png'
import facebook from "../ASSETS/LOGOS/facebook-icon.webp"
import insta from "../ASSETS/LOGOS/Instagram-icon.webp"
import twitter from "../ASSETS/LOGOS/twitter-icon.webp"
import linkedin from '../ASSETS/LOGOS/linkedinWhiteLogo3.ico'

function Footer() {

  var icons = [
    // linkedin,
    { icon: insta, link: "https://www.instagram.com/starfluenza/" },
    { icon: linkedin, link: "https://www.linkedin.com/company/starfluenza" },
    { icon: facebook, link: "https://www.facebook.com/profile.php?id=100087741128749" }
  ]


  return (
    <div className='footer'>

      <div className="footer_details">
        <div id="footer_icons">
          {
            icons.map((e, i) => <div key={i} className='icon'><a key={i} href={e.link}><img className='iconImg' src={e.icon} alt="" /></a></div>)
          }
          <a
            href="https://wa.me/+918007136123"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
          </a>

        </div>
        <div className="footer_contact">
          <p className='contactInfo'>
            brands@starfluenza.com
          </p>

          <p id='contactNumber' className='contactInfo'>+91 8007136123</p>
          <p id='contact_location' className='contactInfo'>Mumbai, Maharashtra, India</p>
        </div>
      </div>
      <img id='footer_logo' src={logo} alt="" />

      {/* <div className="footer_left">
        
      </div> */}

      {/* <div className="footer_right"> */}

      {/* </div> */}
    </div>
  )
}

export default Footer