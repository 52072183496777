import React from 'react'
import './Services.css'

function SP_Text() {
  return (
    <div className="SP_Text">
    {/* <h1>Get <span>Ready</span>  to Be A  <span>Star</span> Brand 
        In just <span>3</span> steps</h1> */}
    <h1>Let's get <span>YOUR</span> Brand Trending</h1>

</div>
  )
}

export default SP_Text