import React from 'react'
import vid1 from '../ASSETS/mainVideo.mp4'
import btmVideo from '../ASSETS/btmVideo.mp4'
import VideoCards from '../VIDEOCARDS/VideoCards'
import Carousel from '../CAROUSEL/Carousel'

function CatalogueThree() {
  return (
    <div className='catalogue_three'>
        <video src={vid1} autoPlay loop muted></video>
        <div className="catalogue_three_subcard">
        {/* <video src={btmVideo} autoPlay loop muted></video> */}
        <Carousel/>
        {/* <VideoCards/> */}
        </div>
    </div>
  )
}

export default CatalogueThree