import React from 'react'
import { Link } from 'react-router-dom'
import './CircleCards.css'

function CircleCards() {
  return (
    <div class="circle_card_container">
    <div class="card card-circle">
      <div class="card-icon">
        <i class="fas fa-apple-alt"></i>
      </div>
      <div class="card-body">
        <h5 class="card-title">Fame</h5>
        <p class="card-text">Some quick example text to build on the card title and card content.</p>
        <Link class="circle_card_btn" to= "">Know More</Link>
      </div>
    </div>
    <div class="card card-circle">
      <div class="card-icon">
        <i class="fas fa-apple-alt"></i>
      </div>
      <div class="card-body">
        <h5 class="card-title">Attention</h5>
        <p class="card-text">Some quick example text to build on the card title and card content.</p>
        <Link class="circle_card_btn" to= "">Know More</Link>
      </div>
    </div>
    <div class="card card-circle">
      <div class="card-icon">
        <i class="fas fa-apple-alt"></i>
      </div>
      <div class="card-body">
        <h5 class="card-title">Leads</h5>
        <p class="card-text">Some quick example text to build on the card title and card content.</p>
        <Link class="circle_card_btn" to= "">Know More</Link>
      </div>
    </div>

  {/* <div class="row"> */}
    {/* <!-- card1 --> */}
    {/* <div class="card card-circle">
      <div class="card-icon">
        <i class="fas fa-apple-alt"></i>
      </div>
      <div class="card-body">
        <h5 class="card-title">Fame</h5>
        <p class="card-text">Some quick example text to build on the card title and card content.</p>
        <Link class="btn btn-primary" to= "">Know More</Link>
      </div>
    </div> */}
    {/* <!-- card2 --> */}
    {/* <div class="card card-circle">
      <div class="card-icon">
        <i class="fas fa-cookie"></i>
      </div>
      <div class="card-body">
        <h5 class="card-title">Attention</h5>
        <p class="card-text">Some quick example text to build on the card title and card content.</p>
        <Link class="btn btn-primary" to= "">Know More</Link>
      </div>
    </div> */}
    {/* <!-- card3 --> */}
    {/* <div class="card card-circle ">
      <div class="card-icon">
        <i class="fas fa-carrot"></i>
      </div>
      <div class="card-body">
        <h5 class="card-title">Leads</h5>
        <p class="card-text">Some quick example text to build on the card title and card content.</p>
        <Link class="btn btn-primary" to= "">Know More</Link>
      </div> */}
    {/* </div> */}
  {/* </div> */}
</div>
  )
}

export default CircleCards