import React from 'react'
import Slider from 'react-slick'
import { useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import kiku from '../ASSETS/CATALOGUE/kiku.MOV'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Carousel.css'

import vid1 from '../ASSETS/CATALOGUE/30 sec Paravti Nair 1920 x 1080 .mp4'
import vid2 from '../ASSETS/CATALOGUE/316885373_845066486738316_5973672268781634146_n.MP4'
import vidBack from '../ASSETS/mainVideo.mp4'
import vid3 from '../ASSETS/CATALOGUE/bd4a4e2c-ceba-4fcd-be78-5cdecb845e49.MP4'
import vid4 from '../ASSETS/CATALOGUE/Muscle Curve-NNM(W).mp4'
import vid5 from '../ASSETS/CATALOGUE/Paras Kalnawat_main video.MP4'
import vid6 from '../ASSETS/CATALOGUE/shefalli bagga_1.mp4'
import vid7 from '../ASSETS/CATALOGUE/ShivThakare_Socialmedia_9x16.mp4'

function Carousel({pageType}) {

    const [sliderRef, setSliderRef] = useState(null)
    const ArrowLeft = (props) => (
        <button
            {...props}
            className={'prev btn'}>
            <FaChevronLeft />
        </button>
    );
    const ArrowRight = (props) => (
        <button
            {...props}
            className={'next btn'}>
            <FaChevronRight />
        </button>
    );

    // const sliderSettings = pageType === "multiple" ? {
        
    //     arrows: true,
    //     prevArrow: <ArrowLeft />,
    //     nextArrow: <ArrowRight />,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     infinite: true,
    //     variableWidth: true,
    //     variableHeight: true
    // } : {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     prevArrow: <ArrowLeft />,
    //     nextArrow: <ArrowRight />,
    //   };

    const sliderSettings = {
        
        arrows: true,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        variableHeight: true, 
        responsive : [{
            breakpoint : 480,
            settings :{
                slidesToShow: 1,
                slidesToScroll:1,
                autoplay : true,
                infinite :true,
            }
        },
        {
            breakpoint : 1000,
            settings :{
                slidesToShow: 3,
                slidesToScroll:1,
                autoplay : true,
                infinite :true,
            }
        }]
    } 

    const hotelCards = [
        {
            imageSrc:
                vid1,
            title: 'Studio Room',
            description: 'Lorem ipsum dolor sit amet, consectur dolori Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptates, est.',
            
        },
        {
            imageSrc:
                vid2,
            title: 'Deluxe Room',
            description: 'Lorem ipsum dolor sit amet, consectur dolori Lorem ipsum dong elit. Voluptates, est.',
           
        },
        {
            imageSrc:
                vid3,
            title: 'King Deluxe Room',
            description: 'Lorem ipsum dolor sit amet, consectur dolori Lom dolor sit amet consectetur, adipisicing elit. Voluptates, est.',
            
        },
        {
            imageSrc:
                vid4,
            title: 'Royal Suite',
            description: 'Lorem ipsum dolor sit amet, consectur dolori Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptates, est.',
            
        },
        {
            imageSrc:
                vid5,
            title: 'Royal Suite1',
            description: 'Lorem ipsum dolor sit amet, consectur dolori Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptates, est.',
           
        },
        {
            imageSrc:
                vid6,
            title: 'Royal Suite2',
            description: 'Lorem ipsum dolor sit amet, consectur dolori Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptates, est.',
            
        },
        {
            imageSrc:
                vid7,
            title: 'Royal Suite3',
            description: 'Lorem ipsum dolor sit amet, cconsectetur, adipisicing elit. Voluptates, est.',
            
        },
    ]




    return (
        <div className='carousel'>
            {/* <button onClick={sliderRef?.slickPrev}>
        <FaChevronLeft />
      </button>
      <button onClick={sliderRef?.slickNext}>
        <FaChevronRight />
      </button> */}
    <h3>Hear What Our Celebs Have to Say.</h3>

            <Slider ref={setSliderRef} {...sliderSettings} className="carousel_cards">
                {hotelCards.map((card, index) => (
                    <div key={index} className="carousel_card">
                        <div style={{"overflow" : "hidden"}} className="carousel_card2">
                            {/* <h2>{card.title}</h2> */}
                            {/* <img alt={card.title} src={card.imageSrc} /> */}
                            <video src={card.imageSrc} autoPlay loop muted></video>
                            <div className="carousel_card2_text">
                                <h4>{card.title}</h4>
                                <h6>{card.pricingText}</h6>
                                <p>{card.description}</p>
                                {/* <ul>
                                    {card.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul> */}
                                
                                {/* <button className='button_buy'>Buy Now</button> */}
                            </div>

                        </div>

                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Carousel