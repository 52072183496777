import React from 'react'
import './VideoCard.css'
// C:\Users\LENOVO\Desktop\MediaMatte\views\src\VIDEOCARDS\VideoCard.css

function VideoCards() {
  return (
    <div className='video_cards'>
         <div className="video_card">
            
         </div>
    </div>
  )
}

export default VideoCards