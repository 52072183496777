import React from 'react'
import './AboutUs.css'
import AboutSeg1 from './AboutSeg1'
import Nav from '../../NAV/Nav'
import Footer from '../Footer'
import AboutUsSeg2 from './AboutUsSeg2'

function AboutUs() {
  return (
    <div className='AboutUs'>
        <Nav/>
        <AboutUsSeg2/>
        {/* <AboutSeg1/> */}
        <Footer/>
    </div>
  )
}

export default AboutUs