import React, { useEffect, useState } from 'react'
import CatalogueOne from './CatalogueOne'
import './Catalogue.css'
import Nav_home from '../NAV/Nav_home'
import CatalogueTwo from './CatalogueTwo'
import Footer from '../PAGES/Footer'
import CatalogueThree from './CatalogueThree'
import Loading2 from './Loading2'

function Catalogue() {
  const [showComponent, setshowComponent] = useState(true)

  const [data, setdata] = useState(null)

  useEffect(() => {
    setInterval(() => {
      setshowComponent(false)
    }, 6000);
  
   setdata(<CatalogueOne/>)
    
  }, [])
  
  
  return (
    <div className='catalogue'>
      
      {/* {showComponent ? <Loading2/> :  */}
      <>
      <Nav_home/>
        {data}
        <CatalogueTwo/>
        <CatalogueThree/>
        <Footer/>
      </>
      
      {/* } */}
        
    </div>
  )
}

export default Catalogue